.waitlist-form {
  position: relative;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.waitlist-form h2 {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.waitlist-form .input-group {
  margin-bottom: 15px;
  width: 100%;
}

.waitlist-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #333;
}

.waitlist-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
  box-sizing: border-box;
}

.waitlist-form .waitlist-button {
  width: 100%;
  background-color: #8B8589;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.waitlist-form .waitlist-button:hover {
  background-color: #7A7376;
}

.waitlist-form .cancel-button {
  width: 100%;
  background-color: #FFFFFF;
  color: #8B8589;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.waitlist-form .cancel-button:hover {
  opacity: 50%;
  cursor: pointer;
}

.waitlist-form .error-message {
  color: #f44336;
  margin-bottom: 10px;
  font-size: 0.9em;
}

.waitlist-form p {
  margin-top: 15px;
  font-size: 0.9em;
  text-align: center;
}

.waitlist-form .switch-waitlist {
  background: none;
  border: none;
  color: #8B8589;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  margin-left: 5px;
  text-decoration: underline;
}

.waitlist-form .switch-waitlist:hover {
  color: #7A7376;
}

.waitlist-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}