.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
}

.box {
  display: flex;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.left-section {
  background-color: #f8f8f8;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  flex: 0 0 300px;
  position: relative;
  padding-bottom: 60px;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 0;
}

.timeline-container {
  width: 100%;
  height: 600px;
  overflow: auto;
}

h2 {
  font-size: 1em;
  margin-bottom: 20px;
  text-align: center;
}

.input-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
}

input[type="month"],
input[type="text"]{
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}

.add-button {
  background-color: #8B8589;
  /* Stone color */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
  float: right;
  font-size: 12px;
}

.add-button:hover {
  background-color: #7A7376;
  /* Darker stone color on hover */
}

.date-input {
  display: flex;
  gap: 10px;
}

.date-input select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.spacer {
  flex-grow: 1;
}

.resume-button {
  background-color: #7A7376;
  /* LinkedIn blue */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  position: absolute;
  bottom: 70px;
  left: 20px;
  right: 20px;
  font-size: 12px;
}

.linkedin-button {
  background-color: #0077B5;
  /* LinkedIn blue */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  font-size: 12px;
}

.add-button {
  margin-bottom: 15px;
}

.right-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.color-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-control label {
  font-size: 12px;
}

.color-control input {
  width: 80px;
  padding: 10px;
  font-size: 12px;
}

.color-control button,
.download-button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 12px;
  background-color: #8B8589;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.color-control button:hover,
.download-button:hover:not(:disabled) {
  background-color: #7A7376;
}

.download-button {
  background-color: #8B8589;
}

.download-button:hover:not(:disabled) {
  background-color: #8B8589;
}

.view-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.switch-button {
  width: 120px;
  /* Set a fixed width */
  padding: 10px 0;
  /* Adjust padding */
  margin: 0 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 12px;
  /* Set a consistent font size */
  text-align: center;
  /* Ensure text is centered */
}

.switch-button.active {
  background-color: #8B8589;
  color: white;
}

.vertical-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* max-height: 400px; */
  overflow-y: auto;
}

.timeline-item {
  position: relative;
  width: 100%;
  padding: 10px 0;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #8B8589;
}

.timeline-content {
  background-color: white;
  border: 2px solid #8B8589;
  border-radius: 5px;
  padding: 10px;
  width: 45%;
  margin-left: 55%;
}

.timeline-content h3 {
  margin: 0 0 5px 0;
}

.timeline-content p {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.logout-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.navbar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-tabs {
  grid-column: 2;
  display: flex;
  justify-content: center;
}

.nav-tab {
  background: none;
  border: none;
  padding: 10px 15px;
  margin: 0 10px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s, border-bottom 0.3s;
}

.nav-tab:hover {
  color: #dc8085;
}

.nav-tab.active {
  font-weight: bold;
  color: #dc8085;
  border-bottom: 2px solid #dc8085;
}

.user-avatar-container {
  grid-column: 3;
  justify-self: end;
}

.avatar-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #8B8589;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.avatar-button:hover {
  background-color: #7A7376;
}

.user-panel {
  position: absolute;
  top: 50px;
  right: 0;
  /* min-height: 100px; */
  min-width: 200px;
  height: auto;
  margin-top: 5px;
  margin-right: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.user-panel p {
  font-size: 12px;
}

.user-panel button {
  background: none;
  border: none;
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  color: #333;
  text-align: right;
}

.user-panel button:hover {
  background-color: #f0f0f0;
}

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}