/* src/Pricing.css */
.pricing-container {
  display: flex;
  justify-content: center;
  /* Center items horizontally */
  align-items: flex-start;
  /* Align items to the top vertically */
  padding: 20px;
  /* Add some padding */
  min-height: 100vh;
  /* Ensure the container takes at least full viewport height */
}

.pricing-tile {
  background-color: #fff;
  /* Background color for tiles */
  border: 1px solid #ddd;
  /* Border for tiles */
  border-radius: 8px;
  /* Rounded corners */
  padding: 20px;
  /* Padding inside tiles */
  text-align: center;
  /* Center text inside tiles */
  width: 300px;
  /* Fixed width for tiles to keep them the same size */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  margin-right: 20px;
}

.pricing-title {
  font-size: 24px;
  font-weight: 600;
}

.pricing-val {
  font-size: 40px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 30px;
}

.pricing-desc {
  text-align: center;
  /* margin-left: 0.5rem; */
}

.purchase-btn {
  /* background-color: #ff4081;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer; */
  width: 180px;
  padding: 15px 0;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #8B8589;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.purchase-btn:hover {
  background-color: #7A7376;
  /* Darker button color on hover */
}

.free-tier-btn {
  width: 180px;
  padding: 15px 0;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: none;
  font-size: 14px;
  text-align: center;
}

.feature-item {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.feature-item span {
  margin-left: 0.5rem;
  /* Add space between Check and span */
}

.check-icon {
  color: #4caf50;
  /* Set the check icon color to green */
}